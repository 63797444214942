import React from 'react'
import { Alert, Button, ButtonGroup, FormGroup, Input, Label } from 'reactstrap'
import PropTypes from 'prop-types'

import { getNested } from '../../Util'
import ButtonDropdown from '../ButtonDropdown'
import { Theme as ThemeConfig } from '../Config'
import LocaleSelect from '../LocaleSelect'

import { defaultTheme } from './Theme'

export default class Handover extends React.Component {
  constructor(props) {
    super(props)

    const defaultPreset = this.props.presets.find(preset => preset.default) || {}
    const defaultState = getNested(defaultPreset, 'fields') || {}

    this.state = {
      middlewareUrl: '',
      locale: 'en_US',
      assets: '../sdk/assets',
      customAssetsUrl: '../sdk/custom',
      errors: [],
      showInstruction: false,
      cautionInfo: null,
      enableAttachments: false,
      startVerifai: false,
      theme: defaultTheme,
      ...defaultState
    }
  }

  reset() {
    return new Promise(accept => {
      this.setState(
        {
          startVerifai: false
        },
        accept
      )
    })
  }

  pushError(title, message, error = false) {
    this.setState(({ errors }) => ({
      errors: [{ title, message, error }].concat(errors)
    }))
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ error, errorInfo })
    this.reset().then(() => this.pushError('Frontend Error', error.message, true))
  }

  render() {
    return (
      <>
        <div className="card" style={{ margin: '10px' }}>
          <div className="card-header">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>Handover</h3>
              {this.props.presets.length > 0 && (
                <ButtonGroup>
                  <ButtonDropdown
                    label="Preset"
                    items={this.props.presets.map(preset => ({
                      label: preset.name,
                      onClick: () => this.setState(preset.fields)
                    }))}
                  />
                  <Button
                    onClick={() =>
                      this.setState({
                        errors: [],
                        startVerifai: true
                      })
                    }
                    color="outline-dark"
                  >
                    Start Handover
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </div>
          <div className="card-body">
            {this.state.startVerifai &&
              React.cloneElement(this.props.children, {
                backendUrl: this.state.middlewareUrl,
                assetsUrl: this.state.assets,
                customAssetsUrl: this.state.customAssetsUrl,
                show: true,
                showInstruction: this.state.showInstruction,
                cautionInfo: this.state.cautionInfo,
                enableAttachments: this.state.enableAttachments,
                locale: this.state.locale,
                theme: this.state.theme,
                onCanceled: () => this.setState({ startVerifai: false })
              })}
            {this.state.errors.map(({ title, message, error = false }, idx) => (
              <Alert style={{ marginTop: '10px' }} key={idx} color={error ? 'danger' : 'success'}>
                <h5>{title}</h5>
                {message}
              </Alert>
            ))}
            <FormGroup>
              <Label for="showInstruction">
                Show Instructions
                <br />
                <label className="switch">
                  <input
                    type="checkbox"
                    id="showInstruction"
                    value={this.state.showInstruction}
                    checked={this.state.showInstruction}
                    onChange={e => this.setState({ showInstruction: e.target.checked })}
                  />
                  <span className="slider round" />
                </label>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="enableAttachments">
                Enable attachments
                <br />
                <label className="switch">
                  <input
                    type="checkbox"
                    id="enableAttachments"
                    value={this.state.enableAttachments}
                    checked={this.state.enableAttachments}
                    onChange={e => this.setState({ enableAttachments: e.target.checked })}
                  />
                  <span className="slider round" />
                </label>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="middleware-url">Middleware URL</Label>
              <Input
                type="text"
                name="middlewareUrl"
                id="middleware-url"
                placeholder="Middleware Url"
                value={this.state.middlewareUrl}
                onChange={e => this.setState({ middlewareUrl: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="locale">Locale</Label>
              <LocaleSelect id="locale" onChange={val => this.setState({ locale: val })} />
            </FormGroup>
            <div className="config-block">
              <div className="card">
                <div
                  className="card-header"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Caution screen
                </div>
                <div className="card-body">
                  <div className="d-flex flex-fill justify-content-around flex-wrap">
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="name">Name / Product</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={this.state.cautionInfo?.name || ''}
                          onChange={e =>
                            this.setState({
                              cautionInfo: { ...this.state.cautionInfo, name: e.target.value }
                            })
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="reason">Reason</Label>
                        <Input
                          type="text"
                          name="reason"
                          id="reason"
                          placeholder="Reason"
                          value={this.state.cautionInfo?.reason || ''}
                          onChange={e =>
                            this.setState({
                              cautionInfo: { ...this.state.cautionInfo, reason: e.target.value }
                            })
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormGroup>
              <Label for="assets">Assets</Label>
              <Input
                type="text"
                name="assets"
                id="assets"
                placeholder="Assets"
                value={this.state.assets}
                onChange={e => this.setState({ assets: e.target.value })}
              />
            </FormGroup>
          </div>
        </div>
        <ThemeConfig onConfigChange={({ theme }) => this.setState({ theme })} />
      </>
    )
  }
}

Handover.defaultProps = {
  presets: []
}

Handover.propTypes = {
  children: PropTypes.node,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool,
      fields: PropTypes.shape({
        middlewareUrl: PropTypes.string
      }).isRequired
    })
  )
}
