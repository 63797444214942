import React from 'react'
import {
  ButtonDropdown as BSButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import PropTypes from 'prop-types'

export default class ButtonDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggle() {
    this.setState(state => ({
      isOpen: !state.isOpen
    }))
  }

  renderItems() {
    return (
      <>
        {this.props.items.map((item, idx) => (
          <DropdownItem key={idx} onClick={() => item.onClick()}>
            {item.label}
          </DropdownItem>
        ))}
      </>
    )
  }

  render() {
    return (
      <BSButtonDropdown isOpen={this.state.isOpen} toggle={() => this.toggle()}>
        <DropdownToggle color="outline-dark" caret>
          {this.props.label}
        </DropdownToggle>
        <DropdownMenu>{this.renderItems()}</DropdownMenu>
      </BSButtonDropdown>
    )
  }
}

ButtonDropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  )
}
