import React from 'react'
import { BiSearch } from 'react-icons/all'
import { ButtonGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import PropTypes from 'prop-types'

import Button from './Button'

export default class CheckBoxList extends React.Component {
  constructor(props) {
    super(props)
    this.state = Object.fromEntries(
      this.props.itemList.map(item => [item.id, { ...item, hidden: false, enabled: item.default }])
    )
  }

  componentDidMount() {
    this.onChange()
  }

  onChange() {
    this.props.onChange(
      Object.fromEntries(
        Object.keys(this.state).map(key => [this.state[key].value, this.state[key].enabled])
      )
    )
  }

  search(str) {
    this.setState(state => {
      Object.keys(state).forEach(key => {
        state[key].hidden = !state[key].search(str)
      })

      return state
    })
  }

  setAllItems(enabled) {
    this.setState(
      state => {
        Object.keys(state).forEach(key => {
          state[key].enabled = enabled
        })

        return state
      },
      () => this.onChange()
    )
  }

  toggleItem(key) {
    this.setState(
      state => ({
        [key]: {
          ...state[key],
          enabled: !state[key].enabled
        }
      }),
      () => this.onChange()
    )
  }

  renderItems(enabled) {
    return Object.keys(this.state)
      .filter(key => this.state[key].enabled === enabled)
      .map((key, idx) => {
        const item = this.state[key]
        const hidden = item.hidden ? 'none' : 'inline'

        return (
          <div
            key={idx}
            style={{ display: hidden, margin: '5px' }}
            onClick={() => this.toggleItem(key)}
          >
            {item.label}
          </div>
        )
      })
  }

  renderAllItems() {
    return Object.keys(this.state).map((key, idx) => {
      const item = this.state[key]
      const hidden = item.hidden ? 'none' : 'inline'

      return (
        <div
          key={idx}
          style={{ display: hidden, margin: '5px' }}
          onClick={() => this.toggleItem(key)}
        >
          <Button
            className={this.state[key].enabled ? 'btn btn-dark' : 'btn btn-outline-secondary'}
          >
            {item.label}
          </Button>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.props.title}
          <ButtonGroup>
            <Button className="btn btn-outline-dark" onClick={() => this.setAllItems(false)}>
              Disable All
            </Button>
            <Button className="btn btn-outline-dark" onClick={() => this.setAllItems(true)}>
              Enable All
            </Button>
          </ButtonGroup>
        </div>

        <div className="card-body">
          <div
            hidden={!this.props.showSearch}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                width: '50%',
                minWidth: '300px',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <BiSearch />
                  </InputGroupText>
                </InputGroupAddon>
                <Input onChange={v => this.search(v.target.value)} placeholder="Search" />
              </InputGroup>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flexStart',
              flexWrap: 'wrap',
              minHeight: '48px',
              maxHeight: '300px',
              overflowY: 'auto'
            }}
          >
            {this.renderAllItems()}
          </div>
        </div>
      </div>
    )
  }
}

CheckBoxList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      id: PropTypes.string,
      search: PropTypes.func,
      default: PropTypes.bool
    })
  ).isRequired,
  showSearch: PropTypes.bool.isRequired
}
