import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import PropTypes from 'prop-types'

class FlagIcon extends React.Component {
  render() {
    return (
      <ReactCountryFlag
        countryCode={this.props.code}
        style={this.props.style || {}}
        className={this.props.className || ''}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
        cdnSuffix="svg"
        title={this.props.code}
      />
    )
  }
}
FlagIcon.propTypes = {
  code: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
}

export default FlagIcon
