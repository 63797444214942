import React from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCarousel
} from 'reactstrap'
import PropTypes from 'prop-types'

import { BackendServiceContext } from '../services/BackendService'

import Barcodes from './Barcodes'
import FaceMatch from './FaceMatch'
import IdModel from './IdModel'
import IIntelResult from './IIntelResult'
import MRZ from './MRZ'
import VIZ from './VIZ'

export default class Result extends React.Component {
  static contextType = BackendServiceContext

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      activeTab: 1,
      result: null,
      otp: null,
      internalReference: null,
      isIIntelModalOpen: false,
      iintelImages: []
    }
  }

  setTab(tab) {
    this.setState({ activeTab: tab })
  }

  close() {
    this.setState({
      isOpen: false,
      isIIntelModalOpen: false,
      result: null,
      otp: null,
      internalReference: null,
      iintelImages: []
    })
  }

  show(item, apiToken) {
    this.setState({
      apiToken,
      isOpen: true,
      isIIntelModalOpen: false,
      result: item.verifaiResult,
      otp: item.otp,
      internalReference: item.internalReference
    })
  }

  showIIntel(item, apiToken) {
    this.setState({
      apiToken,
      result: item.verifaiResult,
      isOpen: false,
      isIIntelModalOpen: true,
      otp: item.otp,
      internalReference: item.internalReference
    })
  }

  getIIntelImages() {
    const { iintelImages } = this.state

    if (iintelImages.length === 0) {
      return []
    }

    const images = iintelImages.map((image, index) => {
      let caption = `${image.type}`

      if (image.type !== 'face_match') {
        caption = `${caption} ${image.isCropped ? '(cropped)' : '(original)'}`
      }

      return {
        src: image.url,
        altText: caption,
        key: `image-${index}`,
        className: 'carousel-image',
        caption
      }
    })

    return images.filter(({ src }) => src)
  }

  images() {
    if (this.state.result === null) {
      return []
    }

    const imageList = [
      {
        src: this.state.result.frontImage,
        altText: 'Front',
        caption: '',
        key: 0
      },
      {
        src: this.state.result.backImage,
        altText: 'Back',
        caption: '',
        className: 'carousel-image',
        key: 1
      },
      {
        src: this.state.result.faceImage,
        altText: 'Face',
        caption: '',
        className: 'carousel-image',
        key: 2
      },
      {
        src: this.state.result.frontImageOriginal,
        altText: 'Front Cropped',
        caption: '',
        className: 'carousel-image',
        key: 3
      },
      {
        src: this.state.result.backImageOriginal,
        altText: 'Back Cropped',
        caption: '',
        className: 'carousel-image',
        key: 4
      },
      {
        src: this.state.result.faceImageOriginal,
        altText: 'Face Cropped',
        caption: '',
        className: 'carousel-image',
        key: 5
      }
    ].filter(({ src }) => src)

    const imagesLength = imageList.length

    this.state.result.attachments.forEach((attachment, index) => {
      const caption = `Attachment ${index + 1}`

      imageList.push({
        src: attachment.source,
        altText: caption,
        caption,
        className: 'carousel-image',
        key: imagesLength + index
      })
    })

    return imageList.map(item => ({ ...item, src: `data:image/png;base64, ${item.src}` }))
  }

  attachments() {
    const { iintelImages } = this.state

    if (iintelImages.length === 0) {
      return []
    }

    const images = iintelImages.map((image, index) => {
      let caption = `${image.type}`

      if (image.type !== 'face_match') {
        caption = `${caption} ${image.isCropped ? '(cropped)' : '(original)'}`
      }

      return {
        src: image.url,
        altText: caption,
        key: `image-${index}`,
        className: 'carousel-image',
        caption
      }
    })

    return images.filter(({ src }) => src)
  }

  render() {
    return (
      <>
        {this.state.result && (
          <div>
            <Modal
              isOpen={this.state.isOpen}
              toggle={() => this.close()}
              size="lg"
              style={{ maxWidth: '50rem', width: '100%' }}
            >
              <ModalHeader toggle={() => this.close()}>Verifai Result</ModalHeader>
              <ModalBody style={{ paddingTop: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      maxWidth: '31rem'
                    }}
                  >
                    <UncontrolledCarousel autoPlay={false} interval={false} items={this.images()} />
                  </div>
                </div>
                <Nav tabs style={{ paddingTop: '4px' }}>
                  <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink active={this.state.activeTab === 1} onClick={() => this.setTab(1)}>
                      Model
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink active={this.state.activeTab === 2} onClick={() => this.setTab(2)}>
                      MRZ
                    </NavLink>
                  </NavItem>
                  {this.props.showViz && (
                    <NavItem style={{ cursor: 'pointer' }}>
                      <NavLink active={this.state.activeTab === 3} onClick={() => this.setTab(3)}>
                        VIZ
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink active={this.state.activeTab === 4} onClick={() => this.setTab(4)}>
                      Barcodes
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink active={this.state.activeTab === 5} onClick={() => this.setTab(5)}>
                      Face Matching
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  style={{
                    minHeight: '20rem',
                    maxHeight: '20rem',
                    overflowY: 'auto'
                  }}
                  activeTab={`${this.state.activeTab}`}
                >
                  <TabPane tabId="1">
                    <IdModel {...this.state.result.idModel} />
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.result.mrzData ? (
                      <MRZ mrz={this.state.result.mrzData} />
                    ) : (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}
                      >
                        <p>
                          <RiErrorWarningLine style={{ marginBottom: '4px' }} /> There was no MRZ
                          detected
                        </p>
                      </div>
                    )}
                  </TabPane>
                  {this.props.showViz && (
                    <TabPane tabId="3">
                      {this.state.result.vizData &&
                      Object.keys(this.state.result.vizData).length > 0 ? (
                        <VIZ viz={this.state.result.vizData} />
                      ) : (
                        <div
                          style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}
                        >
                          <p>
                            <RiErrorWarningLine style={{ marginBottom: '4px' }} /> There was no text
                            detected in Visual Inspection Zone
                          </p>
                        </div>
                      )}
                    </TabPane>
                  )}
                  <TabPane tabId="4">
                    {this.state.result.barcodes.length > 0 ? (
                      <Barcodes barcodes={this.state.result.barcodes || []} />
                    ) : (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}
                      >
                        <p>
                          <RiErrorWarningLine style={{ marginBottom: '4px' }} /> There were no
                          barcodes detected
                        </p>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tabId="5">
                    {this.state.result.faceMatchData ? (
                      <FaceMatch data={this.state.result.faceMatchData} />
                    ) : (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}
                      >
                        <p>
                          <RiErrorWarningLine style={{ marginBottom: '4px' }} /> There were no Face
                          Match results
                        </p>
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.isIIntelModalOpen}
              toggle={() => this.close()}
              size="lg"
              style={{ maxWidth: '50rem', width: '100%' }}
            >
              <ModalHeader toggle={() => this.close()}>I-Intel Result</ModalHeader>
              <ModalBody style={{ paddingTop: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      maxWidth: '31rem'
                    }}
                  >
                    <UncontrolledCarousel
                      autoPlay={false}
                      interval={false}
                      items={this.getIIntelImages()}
                    />
                  </div>
                </div>

                <IIntelResult
                  internalReference={this.state.internalReference}
                  setImages={images => this.setState({ iintelImages: images })}
                  apiToken={this.state.apiToken}
                />
              </ModalBody>
            </Modal>
          </div>
        )}
      </>
    )
  }
}

Result.propTypes = {
  showViz: PropTypes.bool
}
