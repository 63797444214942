import React, { Component } from 'react'
import { AiOutlineIdcard } from 'react-icons/ai'
import { FaCarSide, FaPassport, FaSuitcase } from 'react-icons/fa'
import { FiGlobe } from 'react-icons/fi'
import { RiVisaFill } from 'react-icons/ri'
import ReactTimeAgo from 'react-time-ago'
import { Button, Table } from 'reactstrap'
import PropTypes from 'prop-types'

import { BackendServiceContext } from '../services/BackendService'

import FlagIcon from './FlagIcon'

const iconMap = {
  P: <FaPassport style={{ marginTop: '-2px' }} />,
  I: <AiOutlineIdcard style={{ marginTop: '-2px' }} />,
  D: <FaCarSide style={{ marginTop: '-2px' }} />,
  RP: <FaSuitcase style={{ marginTop: '-4px' }} />,
  V: <RiVisaFill />,
  default: <FiGlobe style={{ marginTop: '-4px' }} />
}

class ResultsList extends Component {
  static contextType = BackendServiceContext

  constructor(props, context) {
    super(props, context)
    this.onOpenClicked = this.onOpenClicked.bind(this)
  }

  getDocumentIcon(code) {
    return iconMap[code] || iconMap.default
  }

  onOpenClicked(e, isIIntel = false) {
    if (this.props.show) {
      const { idx } = e.target.dataset

      this.props.show(this.props.results[idx], isIIntel)
    }
  }

  render() {
    return (
      <Table className="run-result-table" striped>
        <thead className="run-result-head">
          <tr>
            <th>Since</th>
            <th>Session id</th>
            <th>Type</th>
            <th>Country</th>
            <th>Model</th>
            <th>Show</th>
          </tr>
        </thead>
        <tbody className="run-result-body">
          {this.props.results.map((item, idx) => {
            const { idModel } = item.verifaiResult

            return (
              <tr key={idx}>
                <td>
                  <ReactTimeAgo date={item.dateTime} locale="en-US" timeStyle="twitter" /> ago
                </td>
                <td>{item.sessionId}</td>
                <td>{idModel?.type ? this.getDocumentIcon(idModel.type) : '-'}</td>
                <td>{idModel?.country ? <FlagIcon code={idModel.country} /> : '-'}</td>
                <td>{idModel?.model ? idModel.model : '-'}</td>
                <td>
                  {item.verifaiResult ? (
                    <>
                      <Button data-idx={idx} color="outline-dark" onClick={this.onOpenClicked}>
                        Open
                      </Button>

                      <Button
                        data-idx={idx}
                        color="outline-dark"
                        onClick={event => this.onOpenClicked(event, true)}
                      >
                        I-Intel
                      </Button>
                    </>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

ResultsList.propTypes = {
  results: PropTypes.array.isRequired,
  show: PropTypes.func.isRequired
}

export default ResultsList
