import React from 'react'
import { FaReact, FaRegCheckSquare, FaServer, FaTshirt } from 'react-icons/all'
import { HashRouter as Router } from 'react-router-dom'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import {
  Backend as BackendConfig,
  ExtraFeatures as ExtraFeaturesConfig,
  Frontend as FrontendConfig,
  Theme as ThemeConfig
} from './components/Config'
import { defaultTheme } from './components/config/Theme'
import Result from './components/Result'
import RunVerifai from './components/RunVerifai'
import { BackendServiceProvider } from './services/BackendService'

class DevApp extends React.Component {
  constructor(props) {
    super(props)
    this.resultRef = React.createRef()
    this.state = {
      config: {
        backendUrl: '',
        tenant: '',
        theme: defaultTheme,
        allowEditPrivacyFilters: props.tabs.frontend.fields.privacyFilters.value,
        enableViz: props.tabs.extraFeatures.fields.viz.value,
        faceMatching: props.tabs.extraFeatures.fields.faceMatching.value
      },
      result: null,
      api: 'external',
      activeTab: 'frontend',
      settings: {
        frontend: props.tabs.frontend,
        backend: props.tabs.backend,
        extraFeatures: props.tabs.extraFeatures,
        theme: props.tabs.theme,
        api: props.api
      }
    }
    this.onFinish = this.onFinish.bind(this)
    this.setTab = this.setTab.bind(this)
    this.mergeConfig = this.mergeConfig.bind(this)
    this.setApi = this.setApi.bind(this)
  }

  onFinish(result, isIIntel, apiToken) {
    if (result) {
      if (isIIntel) {
        this.resultRef.current.showIIntel(result, apiToken)
      } else {
        this.resultRef.current.show(result, apiToken)
      }
    }
  }

  setTab(e) {
    this.setState({ activeTab: e.target.dataset.tab })
  }

  mergeConfig(newConfig) {
    this.setState(({ config }) => ({ config: { ...config, ...newConfig } }))
  }

  setApi(api) {
    this.setState({ api })
  }

  render() {
    return (
      <BackendServiceProvider
        backendUrl={this.state.config.backendUrl}
        tenant={this.state.config.tenant}
        version="1"
        api={this.state.api}
      >
        <div className="DevAppContainer">
          <div className="DevApp">
            <h1 style={{ margin: '10px' }}>Verifai Web SDK</h1>
            <hr />
            <Router>
              <RunVerifai config={this.state.config} onFinish={this.onFinish}>
                {this.props.children}
              </RunVerifai>
              <Result
                showViz={this.state.config.enableViz}
                result={this.state.result}
                ref={this.resultRef}
              />
              <div>
                <Nav tabs>
                  {this.state.settings.frontend.visible && (
                    <NavItem>
                      <NavLink
                        data-tab="frontend"
                        className={classnames({ active: this.state.activeTab === 'frontend' })}
                        onClick={this.setTab}
                      >
                        <FaReact /> Frontend
                      </NavLink>
                    </NavItem>
                  )}
                  {this.state.settings.extraFeatures.visible && (
                    <NavItem>
                      <NavLink
                        data-tab="extraFeatures"
                        className={classnames({ active: this.state.activeTab === 'extraFeatures' })}
                        onClick={this.setTab}
                      >
                        <FaRegCheckSquare /> Extra Features
                      </NavLink>
                    </NavItem>
                  )}
                  {this.state.settings.theme.visible && (
                    <NavItem>
                      <NavLink
                        data-tab="theme"
                        className={classnames({ active: this.state.activeTab === 'theme' })}
                        onClick={this.setTab}
                      >
                        <FaTshirt /> Theme
                      </NavLink>
                    </NavItem>
                  )}
                  {this.state.settings.backend.visible && (
                    <NavItem>
                      <NavLink
                        data-tab="backend"
                        className={classnames({ active: this.state.activeTab === 'backend' })}
                        onClick={this.setTab}
                      >
                        <FaServer /> Backend
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="frontend">
                    <FrontendConfig
                      onConfigChange={this.mergeConfig}
                      settings={this.state.settings.frontend}
                    />
                  </TabPane>
                  <TabPane tabId="extraFeatures">
                    <ExtraFeaturesConfig
                      onConfigChange={this.mergeConfig}
                      settings={this.state.settings.extraFeatures}
                    />
                  </TabPane>
                  <TabPane tabId="theme">
                    <ThemeConfig onConfigChange={this.mergeConfig} />
                  </TabPane>
                  <TabPane tabId="backend">
                    <BackendConfig
                      onBackendChange={this.setApi}
                      onConfigChange={this.mergeConfig}
                      api={this.props.api}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Router>
          </div>
        </div>
      </BackendServiceProvider>
    )
  }
}

DevApp.defaultProps = {
  tabs: {
    frontend: {
      visible: true
    },
    extraFeatures: {
      visible: false
    },
    theme: {
      visible: true
    },
    backend: {
      visible: true
    }
  }
}

DevApp.propTypes = {
  children: PropTypes.node,
  api: PropTypes.shape({
    internal: PropTypes.shape({
      default: PropTypes.bool,
      enabled: PropTypes.bool,
      presets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          default: PropTypes.bool,
          fields: PropTypes.shape({
            backendUrl: PropTypes.string,
            middlewareUrl: PropTypes.string,
            version: PropTypes.number,
            handoverUrl: PropTypes.string
          })
        })
      )
    }),
    external: PropTypes.shape({
      default: PropTypes.bool,
      enabled: PropTypes.bool,
      presets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          default: PropTypes.bool,
          fields: PropTypes.shape({
            backendUrl: PropTypes.string,
            middlewareUrl: PropTypes.string,
            version: PropTypes.number,
            handoverUrl: PropTypes.string
          })
        })
      )
    })
  }),
  tabs: PropTypes.shape({
    frontend: PropTypes.shape({
      visible: PropTypes.bool,
      fields: PropTypes.any
    }),
    extraFeatures: PropTypes.shape({
      visible: PropTypes.bool,
      fields: PropTypes.shape({
        viz: PropTypes.shape({
          visible: PropTypes.bool,
          value: PropTypes.bool
        }),
        faceMatching: PropTypes.shape({
          visible: PropTypes.bool,
          value: PropTypes.bool
        }),
        enableAttachments: PropTypes.shape({
          visible: PropTypes.bool,
          value: PropTypes.bool
        })
      })
    }),
    theme: PropTypes.shape({
      visible: PropTypes.bool
    }),
    backend: PropTypes.shape({
      visible: PropTypes.bool
    })
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string,
      primaryHover: PropTypes.string,
      body: PropTypes.string,
      bodyHover: PropTypes.string,
      inverted: PropTypes.string,
      invertedHover: PropTypes.string,
      error: PropTypes.string,
      background: PropTypes.string,
      startButton: PropTypes.string,
      startButtonInverted: PropTypes.string
    })
  })
}

export default DevApp
