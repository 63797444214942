import React from 'react'
import ReactDom from 'react-dom'
import * as Sentry from '@sentry/react'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import DevApp from './DevApp'
import HandoverApp from './HandoverApp'
import { getNested } from './Util'

import './index.css'

TimeAgo.addDefaultLocale(en)

function render(
  elementId,
  Verifai,
  configUrl = null,
  uiConfigUrl = 'ui-config.json',
  electron = null
) {
  Promise.all([
    fetchConfig(configUrl)
      .catch(console.warn)
      .then(config => {
        initSentry(getNested(config, 'sentry') || null).catch(console.warn)

        return config
      }),
    fetchConfig(uiConfigUrl).catch(console.warn)
  ]).then(function (valArray) {
    const config = valArray[0]
    const settings = valArray[1]

    return ReactDom.render(
      <DevApp api={getNested(config, 'api')} tabs={getNested(settings, 'tabs')}>
        <Verifai electron={electron} />
      </DevApp>,
      document.getElementById(elementId)
    )
  })
}

function handover(elementId, Handover, configUrl = null) {
  fetchConfig(configUrl)
    .catch(console.warn)
    .then(config => {
      initSentry(getNested(config, 'sentry') || null).catch(console.warn)

      return config
    })
    .then(config => getNested(config, 'handover') || [])
    .then(presets =>
      ReactDom.render(
        <HandoverApp presets={presets}>
          <Handover />
        </HandoverApp>,
        document.getElementById(elementId)
      )
    )
}

function fetchConfig(url) {
  if (url) {
    const errorMsg = 'Could not fetch DevApp config:'

    return fetch(url)
      .then(response =>
        response.ok ? response : Promise.reject(new Error(`${errorMsg} ${response.status}`))
      )
      .then(response => response.json())
  }

  return Promise.reject(new Error('No config url given for DevApp'))
}

function initSentry(sentryConfig) {
  if (sentryConfig) {
    try {
      Sentry.init(sentryConfig)

      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }
  console.info('No Sentry config, skipping...')

  return Promise.resolve()
}

export { render, handover }
