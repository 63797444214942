import React from 'react'
import PropTypes from 'prop-types'

export default class Button extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    e.preventDefault()

    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    return (
      <button
        id={this.props.id}
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.onClick}
      >
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
