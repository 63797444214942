import React from 'react'
import { BsBoxArrowInDownLeft, BsBoxArrowUpRight } from 'react-icons/bs'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

export default class DisplaySelect extends React.Component {
  constructor(props) {
    super(props)

    this.options = [
      { idx: 0, val: 'modal', name: 'Modal', Icon: BsBoxArrowUpRight },
      { idx: 1, val: 'inline', name: 'Inline', Icon: BsBoxArrowInDownLeft }
    ]

    this.state = {
      current: this.optionFromValue(props.value),
      isOpen: false
    }

    this.onClick = this.onClick.bind(this)
    this.setCurrent = this.setCurrent.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const option = this.optionFromValue(this.props.value)

      this.setCurrent(option)
    }
  }

  setCurrent(option) {
    this.setState({ current: option }, () => this.props.onChange(option.val))
  }

  optionFromValue(value) {
    return this.options.find(({ val }) => val === value) || this.options[0]
  }

  onClick({
    target: {
      dataset: { idx }
    }
  }) {
    const current = this.options[idx]

    this.setCurrent(current)
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  renderOption(option) {
    const { Icon, name } = option

    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '5px',
          pointerEvents: 'none'
        }}
      >
        <Icon /> {name}
      </div>
    )
  }

  render() {
    return (
      <Dropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={() => this.toggleDropdown()}
        direction="down"
      >
        <DropdownToggle color="outline-dark" caret>
          {this.renderOption(this.state.current)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Display</DropdownItem>
          {this.options.map((option, idx) => (
            <DropdownItem
              key={idx}
              data-idx={idx}
              active={this.state.current.idx === idx}
              onClick={this.onClick}
            >
              {this.renderOption(option)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

DisplaySelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}
