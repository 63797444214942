import React from 'react'
import { FaReact } from 'react-icons/all'
import { FaFlag, FaRegFlag } from 'react-icons/fa'
import { FormGroup, Input, Label } from 'reactstrap'
import PropTypes from 'prop-types'

import CountrySelect from '../CountrySelect'
import CountrySuggestedSelect from '../CountrySuggestedSelect'
import DisplaySelect from '../DisplaySelect'
import DocumentSelect from '../DocumentSelect'
import FinalImageResultSelect from '../FinalImageResultSelect'
import LocaleSelect from '../LocaleSelect'
import UploadSelect from '../UploadSelect'

import countryList from './countryList.json'

export default class Frontend extends React.Component {
  constructor(props) {
    super(props)

    this.countryList = countryList
    this.config = props.settings.fields

    this.state = {
      locale: this.config.locale.value,
      assets: this.config.assets.fields.defaultAssets.value,
      customAssets: this.config.assets.fields.customAssets.value,
      nonce: this.config.nonce.value,
      finalImageResult: this.config.finalImageResult.value,
      uploadType: this.config.uploadType.value,
      documentTypes: [],
      countryWhiteList: [],
      countryBlackList: [],
      countryCodeOption: this.config.countryCodeOption.value,
      showSms: this.config.showSms.value,
      closable: this.config.closable.value,
      showInstruction: this.config.showInstruction.value,
      showGreeting: this.config.showGreeting.value,
      cautionInfo: {
        name: this.config.cautionInfo.fields.name.value,
        reason: this.config.cautionInfo.fields.reason.value
      },
      allowEditPrivacyFilters: this.config.privacyFilters.value,
      faceMatchMobileOnly: this.config.faceMatchMobileOnly.value,
      display: this.config.display.value,
      enableAttachments: this.config.enableAttachments.value
    }

    this.inputChange = this.inputChange.bind(this)
    this.setFinalImageResult = this.setFinalImageResult.bind(this)
  }

  inputChange(state) {
    this.setState(state, () => this.props.onConfigChange(this.state))
  }

  setFinalImageResult(finalImageResult) {
    this.inputChange({ finalImageResult })
  }

  render() {
    return (
      <div className="card" style={{ margin: '10px' }}>
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>
              <FaReact /> Frontend Config
            </h3>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-around flex-wrap">
            {this.config.locale.visible && (
              <div className="d-flex flex-fill justify-content-around flex-wrap">
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="locale">Locale</Label>
                    <LocaleSelect id="locale" onChange={val => this.inputChange({ locale: val })} />
                  </FormGroup>
                </div>
              </div>
            )}
            {this.config.countryCodeOption.visible && (
              <div className="d-flex flex-fill justify-content-around flex-wrap">
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="countryCodeOption">Country Code Suggested</Label>
                    <CountrySuggestedSelect
                      countryList={this.countryList}
                      show={false}
                      sugg={this.state.countryCodeOption}
                      id="countryCodeOption"
                      onChange={val => this.inputChange({ countryCodeOption: val })}
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {this.config.display.visible && (
              <div className="d-flex flex-fill justify-content-around flex-wrap">
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="display">Display</Label>
                    <DisplaySelect
                      id="display"
                      onChange={val => this.inputChange({ display: val })}
                      value={this.state.display}
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            {this.config.finalImageResult.visible && (
              <div className="d-flex flex-fill justify-content-around flex-wrap">
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="finalImageResult">Final Image Result</Label>
                    <FinalImageResultSelect
                      id="finalImageResult"
                      onChange={this.setFinalImageResult}
                    />
                  </FormGroup>
                </div>
              </div>
            )}
            <div className="d-flex flex-fill justify-content-around flex-wrap">
              {this.config.showSms.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="showSms">
                      Sms Show
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="showSms"
                            value={this.state.showSms}
                            checked={this.state.showSms}
                            onChange={e => this.inputChange({ showSms: e.target.checked })}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}
              {this.config.showInstruction.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="showInstruction">
                      Show Instructions
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="showInstruction"
                            value={this.state.showInstruction}
                            checked={this.state.showInstruction}
                            onChange={e => this.inputChange({ showInstruction: e.target.checked })}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}
              {this.config.closable.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="closable">
                      Closable
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="closable"
                            value={this.state.closable}
                            checked={this.state.closable}
                            onChange={e => this.inputChange({ closable: e.target.checked })}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}
              {this.config.showGreeting.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="showGreeting">
                      Show Greeting
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="showGreeting"
                            value={this.state.showGreeting}
                            checked={this.state.showGreeting}
                            onChange={e => this.inputChange({ showGreeting: e.target.checked })}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}
              {this.config.privacyFilters.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="allowEditPrivacyFilters">
                      Allow Edit Privacy Filters
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="privacyFilters"
                            value={this.state.allowEditPrivacyFilters}
                            checked={this.state.allowEditPrivacyFilters}
                            onChange={e =>
                              this.inputChange({ allowEditPrivacyFilters: e.target.checked })
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}
              {this.config.faceMatchMobileOnly.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="faceMatchMobileOnly">
                      Face Match Mobile Only
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="faceMatchMobileOnly"
                            value={this.state.faceMatchMobileOnly}
                            checked={this.state.faceMatchMobileOnly}
                            onChange={e =>
                              this.inputChange({ faceMatchMobileOnly: e.target.checked })
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}

              {this.config.enableAttachments.visible && (
                <div className="flex-fill">
                  <FormGroup>
                    <Label for="faceMatchMobileOnly">
                      Enable attachments
                      <br />
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="enableAttachments"
                            value={this.state.enableAttachments}
                            checked={this.state.enableAttachments}
                            onChange={e =>
                              this.inputChange({ enableAttachments: e.target.checked })
                            }
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              )}

              {this.config.nonce.visible && (
                <div className="d-flex flex-fill justify-content-around flex-wrap">
                  <div className="flex-fill">
                    <FormGroup className="inputBox">
                      <Label for="nonce">Nonce</Label>
                      <Input
                        type="text"
                        name="nonce"
                        id="nonce"
                        placeholder="Nonce"
                        value={this.state.nonce}
                        onChange={e => this.inputChange({ nonce: e.target.value })}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
            </div>
          </div>

          {this.config.cautionInfo.visible && (
            <div className="config-block">
              <div className="card">
                <div
                  className="card-header"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Caution screen
                </div>
                <div className="card-body">
                  <div className="d-flex flex-fill justify-content-around flex-wrap">
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="name">Name / Product</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={this.state.cautionInfo.name}
                          onChange={e =>
                            this.inputChange({
                              cautionInfo: { ...this.state.cautionInfo, name: e.target.value }
                            })
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="reason">Reason</Label>
                        <Input
                          type="text"
                          name="reason"
                          id="reason"
                          placeholder="Reason"
                          value={this.state.cautionInfo.reason}
                          onChange={e =>
                            this.inputChange({
                              cautionInfo: { ...this.state.cautionInfo, reason: e.target.value }
                            })
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.config.assets.visible && (
            <div className="config-block">
              <div className="card">
                <div
                  className="card-header"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Assets
                </div>
                <div className="card-body">
                  <div className="d-flex flex-fill justify-content-around flex-wrap">
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="assets">Default Assets</Label>
                        <Input
                          type="text"
                          name="assets"
                          id="assets"
                          placeholder="CDN"
                          value={this.state.assets}
                          onChange={e => this.inputChange({ assets: e.target.value })}
                        />
                      </FormGroup>
                    </div>
                    <div className="flex-fill">
                      <FormGroup className="inputBox">
                        <Label for="customAssets">Custom Assets</Label>
                        <Input
                          type="text"
                          name="customAssets"
                          id="customAssets"
                          placeholder="CDN"
                          value={this.state.customAssets}
                          onChange={e => this.inputChange({ customAssets: e.target.value })}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.config.documentTypes.visible && (
            <div className="config-block">
              <DocumentSelect
                title="Document Types"
                onChange={list => this.inputChange({ documentTypes: list })}
                value={this.config.documentTypes.value}
              />
            </div>
          )}
          {this.config.uploadType.visible && (
            <div className="config-block">
              <UploadSelect
                title="Upload types"
                onChange={list => {
                  this.inputChange({ uploadType: list })
                }}
                value={this.config.uploadType.value}
              />
            </div>
          )}
          {this.config.countryWhiteList.visible && (
            <div className="config-block">
              <CountrySelect
                countryList={this.countryList}
                title={
                  <h5>
                    <FaRegFlag /> Country White List
                  </h5>
                }
                onChange={list => this.inputChange({ countryWhiteList: list })}
                default={false}
              />
            </div>
          )}
          {this.config.countryBlackList.visible && (
            <div className="config-block">
              <CountrySelect
                countryList={this.countryList}
                title={
                  <h5>
                    <FaFlag /> Country Black List
                  </h5>
                }
                onChange={list => this.inputChange({ countryBlackList: list })}
                default={false}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

Frontend.propTypes = {
  onConfigChange: PropTypes.func,
  settings: PropTypes.any
}
