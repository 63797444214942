import React, { useContext } from 'react'
import PropTypes from 'prop-types'

export const BackendServiceContext = React.createContext(null)

export const useBackend = () => useContext(BackendServiceContext)

export class BackendServiceProvider extends React.Component {
  constructor(props) {
    super(props)

    this.api = {
      getOTP: (data, apiToken = null) => this.getOTP(data, apiToken),
      getResult: (sessionId, imageResult, apiToken = null) =>
        this.getResult(sessionId, imageResult, apiToken),
      deleteSession: (sessionId, apiToken = null) => this.deleteSession(sessionId, apiToken),
      getiIntelResult: (internalReference, apiToken = null) =>
        this.getiIntelResult(internalReference, apiToken)
    }

    this.endpoints = {
      internal: {
        getOTP: () => 'auth/token',
        getResult: (sessionId, imageResult) =>
          `session/${sessionId}/verifai-result?image_result=${imageResult}`,
        deleteSession: sessionId => `session/${sessionId}`,
        getiIntelResult: () => console.warn('Webhook is not supported on internal api')
      },
      external: {
        getOTP: () => 'token',
        getResult: (sessionId, imageResult) => `result/${sessionId}/${imageResult}`,
        deleteSession: sessionId => `session/${sessionId}`,
        getiIntelResult: internalReference => `i-intel/result/${internalReference}`
      }
    }
  }

  getTenantHeader() {
    const { tenant } = this.props

    return tenant ? { 'Verifai-Tenant': tenant } : null
  }

  getOTP(data, apiToken = null) {
    const url = this.endpoints[this.props.api].getOTP()
    const endpoint = this.endpointUrl(url)
    const header = this.getTenantHeader()

    return this.fetchData(endpoint, data, 'POST', header, apiToken)
  }

  getResult(sessionId, imageResult, apiToken = null) {
    const url = this.endpoints[this.props.api].getResult(sessionId, imageResult)
    const endpoint = this.endpointUrl(url)
    const header = this.getTenantHeader()

    return this.fetchData(endpoint, null, 'GET', header, apiToken)
  }

  deleteSession(sessionId, apiToken = null) {
    const url = this.endpoints[this.props.api].deleteSession(sessionId)
    const endpoint = this.endpointUrl(url)
    const header = this.getTenantHeader()

    return this.fetchData(endpoint, null, 'DELETE', header, apiToken)
  }

  getiIntelResult(internalReference, apiToken = null) {
    const url = this.endpoints[this.props.api].getiIntelResult(internalReference)
    const endpoint = this.endpointUrl(url)
    const header = this.getTenantHeader()

    return this.fetchData(endpoint, null, 'GET', header, apiToken)
  }

  fetchData(url, data = null, method = 'POST', header = null, apiToken = null) {
    const headers = {
      'Content-Type': 'application/json',
      ...header
    }

    if (apiToken !== null) {
      headers.Authorization = `Bearer ${apiToken}`
    }

    const body = data ? JSON.stringify(data) : null

    return fetch(url, {
      method,
      headers,
      body,
      cache: 'no-cache',
      keepalive: false
    })
      .then(this.checkResponse)
      .then(this.unpackResponse)
  }

  checkResponse(response) {
    if (!response.ok) {
      throw new Error(response.status)
    }

    return Promise.resolve(response)
  }

  unpackResponse(response) {
    return response.json()
  }

  endpointUrl(endpoint) {
    const url = this.props.backendUrl.replace(/\/?$/, '/')

    if (this.props.api === 'internal') {
      return `${url}v${this.props.version}/${endpoint}`
    }

    return `${url}${endpoint}`
  }

  render() {
    return (
      <BackendServiceContext.Provider value={{ ...this.api, api: this.props.api }}>
        {this.props.children}
      </BackendServiceContext.Provider>
    )
  }
}

BackendServiceProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  api: PropTypes.oneOf(['internal', 'external']).isRequired,
  backendUrl: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  tenant: PropTypes.string
}
