import React from 'react'
import PropTypes from 'prop-types'

import { Handover as HandoverConfig } from './components/Config'

class HandoverApp extends React.Component {
  render() {
    return (
      <div className="DevAppContainer">
        <div className="DevApp">
          <h1 style={{ margin: '10px' }}>Verifai Web SDK</h1>
          <hr />
          <HandoverConfig presets={this.props.presets}>{this.props.children}</HandoverConfig>
        </div>
      </div>
    )
  }
}

HandoverApp.propTypes = {
  children: PropTypes.node,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool,
      fields: PropTypes.shape({
        middlewareUrl: PropTypes.string
      }).isRequired
    })
  )
}

export default HandoverApp
