import React from 'react'
import { SketchPicker } from 'react-color'
import {
  AiFillFileImage,
  AiFillTag,
  BsFillInfoCircleFill,
  BsWrench,
  FaDice,
  FaTshirt,
  IoMdColorPalette
} from 'react-icons/all'
import { ButtonGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import PropTypes from 'prop-types'

import Button from '../Button'
import Dot from '../Dot'
import DropDownSelectExtra from '../DropDownSelectExtra'

const corners = {
  modalRadius: {
    corner: '16',
    label: 'modalRadius',
    desc: 'Radius of the modal in length unit px'
  },
  buttonRadius: {
    corner: '24',
    label: 'buttonRadius',
    desc: 'Radius of the buttons in length unit px'
  }
}

const colors = {
  modalBackground: {
    color: '#ffffff',
    label: 'modalBackground',
    desc: 'Background color of the modal'
  },
  buttonBack: { color: '#757575', label: 'buttonBack', desc: 'Color of the back button' },
  buttonBackHover: {
    color: '#636363',
    label: 'buttonBackHover',
    desc: 'Color of the back button if clickable and hovered'
  },
  primary: {
    color: '#0084c8',
    label: 'primary',
    desc: 'Primary color of all main UI elements'
  },
  buttonMainText: {
    color: '#ffffff',
    label: 'buttonMainText',
    desc: 'Color of the main button text'
  },
  bodyText: {
    color: '#757575',
    label: 'bodyText',
    desc: 'Color of the text in the body of the modal'
  },
  bodyErrorText: {
    color: '#e90000',
    label: 'bodyText error',
    desc: 'Color of an error message in the body of the modal'
  },
  textHeader: { color: '#212529', label: 'textHeader', desc: 'Color of the screen titles' },
  textDescription: {
    color: '#757575',
    label: 'textDescription',
    desc: 'Color of the description titles'
  },
  imageForeground: {
    color: '#212529',
    label: 'imageForeground',
    desc: 'Primary image color (Foreground)'
  },
  imageBackground: {
    color: '#f3f3f3',
    label: 'imageBackground',
    desc: 'Secondary image color (Background)'
  },
  // Phone Input
  phoneInputSecondary: {
    color: '#f1f1f1',
    label: 'phoneInputSecondary',
    desc: 'Secondary color for hovers and selected items in Phone Input'
  },
  inputValid: { color: '#ccffcc', label: 'inputValid', desc: 'Color for a valid input field' },
  inputInvalid: {
    color: '#ff9999',
    label: 'inputInvalid',
    desc: 'Color for an invalid input field'
  },
  inputBoxShadow: {
    color: '#0066ff',
    label: 'inputBoxShadow',
    desc: 'Input box shadow color on focus'
  },
  inputBorder: { color: '#d3d3d3', label: 'inputBorder', desc: 'Input box border color' },
  dropdownSeparationLine: {
    color: '#bdbdbd',
    label: 'dropdownSeparationLine',
    desc: 'Color of separation line in dropdown'
  },
  dropdownSecondaryText: {
    color: '#8e8e8e',
    label: 'dropdownSecondaryText',
    desc: 'Text color for secondary text'
  },
  dropdownBoxShadow: {
    color: '#7d7d7d',
    label: 'dropdownBoxShadow',
    desc: 'Shadow color of the dropdown menu'
  }
}

const iconOptions = [
  { idx: 0, name: 'wmf1Image', code: 'wmf1Image' },
  { idx: 1, name: 'wM1Image', code: 'wM1Image' },
  { idx: 2, name: 'wn1Image', code: 'wn1Image' },
  { idx: 3, name: 'wn2Image', code: 'wn2Image' },
  { idx: 4, name: 'bodyCircleImage', code: 'bodyCircleImage' },
  { idx: 5, name: 'scanImage', code: 'scanImage' },
  { idx: 6, name: 'documentVersionArrowBackImage', code: 'documentVersionArrowBackImage' },
  { idx: 7, name: 'documentVersionArrowforwardImage', code: 'documentVersionArrowforwardImage' },
  { idx: 8, name: 'passportImage', code: 'passportImage' },
  { idx: 9, name: 'idCardImage', code: 'idCardImage' },
  { idx: 10, name: 'drivingLicenceImage', code: 'drivingLicenceImage' },
  { idx: 11, name: 'residencePermitImage', code: 'residencePermitImage' },
  { idx: 12, name: 'refugeeTravelDocumentImage', code: 'refugeeTravelDocumentImage' },
  { idx: 13, name: 'goToYourMobileImage', code: 'goToYourMobileImage' },
  { idx: 14, name: 'mobileConnected', code: 'mobileConnected' },
  { idx: 15, name: 'mobileImage', code: 'mobileImage' },
  { idx: 16, name: 'verifaiLogo', code: 'verifaiLogo' },
  { idx: 17, name: 'upload', code: 'upload' },
  { idx: 18, name: 'scanner', code: 'scanner' },
  { idx: 19, name: 'smartphone', code: 'smartphone' },
  { idx: 20, name: 'webcam', code: 'webcam' },
  { idx: 21, name: 'cloudImage', code: 'cloudImage' },
  { idx: 22, name: 'warningImage', code: 'warningImage' },
  { idx: 23, name: 'numberOneImage', code: 'numberOneImage' },
  { idx: 24, name: 'numberTwoImage', code: 'numberTwoImage' },
  { idx: 24, name: 'numberThreeImage', code: 'numberThreeImage' },
  { idx: 26, name: 'instructionsPhoneImage', code: 'instructionsPhoneImage' },
  { idx: 27, name: 'scanDocumentImage', code: 'scanDocumentImage' },
  { idx: 28, name: 'instructionsComputerImage', code: 'instructionsComputerImage' },
  { idx: 29, name: 'backArrow', code: 'backArrow' },
  { idx: 31, name: 'idImage', code: 'idImage' },
  { idx: 32, name: 'circleCameraImage', code: 'circleCameraImage' },
  { idx: 33, name: 'cautionImage', code: 'cautionImage' }
]

const icons = {
  // Example: "idImage": "done-icon.svg"
}

export const defaultTheme = {
  colors: Object.fromEntries(Object.keys(colors).map(key => [key, colors[key].color])),
  corners: Object.fromEntries(Object.keys(corners).map(key => [key, corners[key].corner])),
  icons
}

export default class Theme extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: { ...props.theme, ...defaultTheme },
      colorPickerOpen: null
    }

    this.setColor = this.setColor.bind(this)
    this.setCorner = this.setCorner.bind(this)
    this.setIcon = this.setIcon.bind(this)
    this.onConfigChange = this.onConfigChange.bind(this)
    this.setColors = this.setColors.bind(this)
    this.setCorners = this.setCorners.bind(this)
    this.setIcons = this.setIcons.bind(this)
    this.setRandomColors = this.setRandomColors.bind(this)
    this.setDefault = this.setDefault.bind(this)
  }

  setColor(field, color) {
    this.setState(
      state => ({
        theme: {
          ...state.theme,
          colors: {
            ...state.theme.colors,
            [field]: color
          }
        }
      }),
      this.onConfigChange
    )
  }

  setCorner(field, rad) {
    this.setState(
      state => ({
        theme: {
          ...state.theme,
          corners: {
            ...state.theme.corners,
            [field]: rad
          }
        }
      }),
      this.onConfigChange
    )
  }

  setIcon(field, name) {
    this.setState(
      state => ({
        theme: {
          ...state.theme,
          icons: {
            ...state.theme.icons,
            [field]: name
          }
        }
      }),
      this.onConfigChange
    )
  }

  removeIcon(key) {
    const updatedIcons = this.state.theme.icons

    delete updatedIcons[key]

    this.setState(
      state => ({
        theme: {
          ...state.theme,
          icons: updatedIcons
        }
      }),
      this.onConfigChange
    )
  }

  setColors(colors) {
    this.setState(
      state => ({
        theme: { ...state.theme, colors }
      }),
      this.onConfigChange
    )
  }

  setCorners(corners) {
    this.setState(
      state => ({
        theme: { ...state.theme, corners }
      }),
      this.onConfigChange
    )
  }

  setIcons(icons) {
    this.setState(
      state => ({
        theme: { ...state.theme, icons }
      }),
      this.onConfigChange
    )
  }

  setDefault() {
    this.setCorners(defaultTheme.corners)
    this.setColors(defaultTheme.colors)
    this.setIcons(defaultTheme.icons)
  }

  onConfigChange() {
    if (this.props.onConfigChange) {
      this.props.onConfigChange(this.state)
    }
  }

  setRandomColors() {
    const hexChars = '0123456789ABCDEF'.split('')
    const nrOfChars = 6
    const randomColors = Object.fromEntries(
      Object.keys(colors).map(key => [
        key,
        `#${Array.from(
          { length: nrOfChars },
          () => hexChars[Math.floor(Math.random() * hexChars.length)]
        ).join('')}`
      ])
    )

    this.setColors(randomColors)
  }

  render() {
    return (
      <div className="card" style={{ margin: '10px' }}>
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>
              <FaTshirt /> Theme Config
            </h3>
            <ButtonGroup>
              <Button className="btn btn-outline-dark" onClick={this.setDefault}>
                Default
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <tbody>
              <tr>
                <th>
                  <AiFillTag /> Name
                </th>
                <th>
                  <BsWrench /> Radius
                </th>
                <th>
                  <BsFillInfoCircleFill /> Description
                </th>
              </tr>
              {Object.keys(corners).map((key, idx) => (
                <tr key={idx}>
                  <td>{corners[key].label}</td>
                  <td>
                    <div style={{ width: `${100}px` }}>
                      <Input
                        type="text"
                        name="corner"
                        placeholder="radius"
                        value={this.state.theme.corners[key]}
                        onChange={e => this.setCorner(key, e.target.value)}
                      />
                    </div>
                  </td>
                  <td>
                    <p>{corners[key].desc}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>Theme Colors</h4>
            <ButtonGroup>
              <Button className="btn btn-outline-dark btn-random" onClick={this.setRandomColors}>
                <FaDice /> Random
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <tbody>
              <tr>
                <th>
                  <AiFillTag /> Name
                </th>
                <th>
                  <IoMdColorPalette /> Color
                </th>
                <th>
                  <BsFillInfoCircleFill /> Description
                </th>
              </tr>
              {Object.keys(colors).map((key, idx) => (
                <tr key={idx}>
                  <td>{colors[key].label}</td>
                  <td>
                    <div
                      className="mrz color-link"
                      onClick={() => this.setState(() => ({ colorPickerOpen: key }))}
                    >
                      <Dot fillColor={this.state.theme.colors[key]} />
                      <span>{this.state.theme.colors[key]}</span>
                    </div>
                    {this.state.colorPickerOpen === key && (
                      <div className="color-popover">
                        <div
                          className="color-cover"
                          onClick={() => this.setState({ colorPickerOpen: null })}
                        />
                        <SketchPicker
                          color={this.state.theme.colors[key]}
                          onChange={({ hex }) => this.setColor(key, hex)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <p>{colors[key].desc}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>Custom Icons</h4>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <tbody>
              <tr>
                <th>
                  <AiFillTag /> Name
                </th>
                <th>
                  <AiFillFileImage /> File
                </th>
              </tr>
              {Object.keys(this.state.theme.icons).map((key, idx) => (
                <tr key={idx}>
                  <td>{key}</td>
                  <td>
                    <div>
                      <InputGroup>
                        <Input
                          type="text"
                          name="icon"
                          placeholder="name"
                          value={this.state.theme.icons[key]}
                          onChange={e => this.setIcon(key, e.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            className="btn btn-outline-danger"
                            onClick={() => this.removeIcon(key)}
                          >
                            {' '}
                            ×{' '}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <DropDownSelectExtra
                    id="iconSelect"
                    title="Select Icon"
                    onChange={e => this.setIcon(e, '')}
                    options={iconOptions}
                    activeCode="idImage"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

Theme.propTypes = {
  onConfigChange: PropTypes.func,
  theme: PropTypes.shape({
    corners: PropTypes.shape({
      modalRadius: PropTypes.string,
      buttonRadius: PropTypes.string
    }),
    colors: PropTypes.shape({
      modalBackground: PropTypes.string,
      buttonBack: PropTypes.string,
      buttonBackHover: PropTypes.string,
      buttonMain: PropTypes.string,
      buttonMainHover: PropTypes.string,
      buttonMainText: PropTypes.string,
      bodyText: PropTypes.string,
      bodyErrorText: PropTypes.string,
      textHeader: PropTypes.string,
      textDescription: PropTypes.string,
      imageForeground: PropTypes.string,
      imageBackground: PropTypes.string
    }),
    icons: PropTypes.any
  })
}
