import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

export default class FinalImageResultSelect extends React.Component {
  constructor(props) {
    super(props)

    this.finalResultOptions = [
      { idx: 0, name: 'Original Image', code: 'original' },
      { idx: 1, name: 'Cropped Image', code: 'cropped' },
      { idx: 2, name: 'Original & Cropped Images', code: 'all' }
    ]

    this.state = {
      current: this.finalResultOptions[0],
      isOpen: false
    }

    this.setFinalResult = this.setFinalResult.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  setFinalResult({
    target: {
      dataset: { idx }
    }
  }) {
    const current = this.finalResultOptions[idx]

    this.setState({ current }, this.props.onChange(current.code))
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    return (
      <Dropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={this.toggleDropdown}
        direction="down"
      >
        <DropdownToggle color="outline-dark" caret>
          {this.state.current.name}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Final Image Result</DropdownItem>
          {this.finalResultOptions.map((option, idx) => (
            <DropdownItem
              key={idx}
              data-idx={idx}
              active={this.state.current.idx === idx}
              onClick={this.setFinalResult}
            >
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

FinalImageResultSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func
}
