import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

export default class FaceMatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confidence: props.data.confidence,
      matches: props.data.matches
    }
  }

  render() {
    return (
      <div style={{ paddingTop: '4px' }}>
        <Table>
          <thead>
            <tr>
              <th>Match</th>
              <th>Confidence</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.matches ? 'Match' : 'No match'}</td>
              <td>{this.state.confidence}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

FaceMatch.defaultProps = {
  data: {}
}

FaceMatch.propTypes = {
  data: PropTypes.object
}
