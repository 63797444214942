import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

import FlagIcon from './FlagIcon'

export default class CountrySuggestedSelect extends React.Component {
  constructor(props) {
    super(props)

    const { countryList } = this.props

    this.countryList = countryList.map((c, index) => ({
      idx: index,
      icon: (
        <div className={`country-div ${c.toLowerCase()}`}>
          <FlagIcon code={c.toLowerCase()} /> <span className="monospace">{c}</span>
        </div>
      ),
      name: c
    }))

    this.state = {
      current: this.listFromValue(props.sugg),
      isOpen: false,
      show: props.show
    }

    this.setCountry = this.setCountry.bind(this)
  }

  listFromValue(value) {
    return this.countryList.find(({ name }) => name === value) || this.countryList[0]
  }

  setCountry({
    target: {
      dataset: { idx }
    }
  }) {
    const current = this.countryList[idx]

    this.setState({ current }, () => this.props.onChange(current.name))
    this.setState({ show: true })
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    return (
      <Dropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={() => this.toggleDropdown()}
        direction="down"
      >
        <DropdownToggle color="outline-dark" caret>
          {this.state.show && this.state.current.icon}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Country Suggestion</DropdownItem>
          {this.countryList.map((option, idx) => (
            <DropdownItem
              key={idx}
              data-idx={idx}
              active={this.state.current.idx === idx}
              onClick={this.setCountry}
            >
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

CountrySuggestedSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  sugg: PropTypes.string,
  show: PropTypes.bool,
  countryList: PropTypes.array
}
