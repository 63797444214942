import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

import FlagIcon from './FlagIcon'

export default class LocaleSelect extends React.Component {
  constructor(props) {
    super(props)

    this.locales = [
      { idx: 0, code: 'en_US', name: 'English (US)', iconCode: 'us' },
      { idx: 1, code: 'en_GB', name: 'English (GB)', iconCode: 'gb' },
      { idx: 2, code: 'nl_NL', name: 'Dutch', iconCode: 'nl' },
      { idx: 3, code: 'de_DE', name: 'German', iconCode: 'de' },
      { idx: 4, code: 'ar_SA', name: 'Arabic', iconCode: 'sa' },
      { idx: 5, code: 'bg_BG', name: 'Bulgarian', iconCode: 'bg' },
      { idx: 6, code: 'cs_CZ', name: 'Czech', iconCode: 'cz' },
      { idx: 7, code: 'da_DK', name: 'Danish', iconCode: 'dk' },
      { idx: 8, code: 'es_ES', name: 'Spanish', iconCode: 'es' },
      { idx: 9, code: 'fi_FI', name: 'Finnish', iconCode: 'fi' },
      { idx: 10, code: 'fr_FR', name: 'French', iconCode: 'fr' },
      { idx: 11, code: 'el_GR', name: 'Greek', iconCode: 'gr' },
      { idx: 12, code: 'hr_HR', name: 'Croatian', iconCode: 'hr' },
      { idx: 13, code: 'ko_KR', name: 'Korean', iconCode: 'kr' },
      { idx: 14, code: 'no_NO', name: 'Norwegian', iconCode: 'no' },
      { idx: 15, code: 'pl_PL', name: 'Polish', iconCode: 'pl' },
      { idx: 16, code: 'pt_PT', name: 'Portuguese', iconCode: 'pt' },
      { idx: 17, code: 'ro_RO', name: 'Romanian', iconCode: 'ro' },
      { idx: 18, code: 'sv_SE', name: 'Swedish', iconCode: 'se' },
      { idx: 19, code: 'tr_TR', name: 'Turkish', iconCode: 'tr' },
      { idx: 20, code: 'it_IT', name: 'Italian', iconCode: 'it' },
      { idx: 21, code: 'et_EE', name: 'Estonian', iconCode: 'et' },
      { idx: 22, code: 'hu_HU', name: 'Hungarian', iconCode: 'hu' },
      { idx: 23, code: 'ja_JP', name: 'Japanese', iconCode: 'jp' },
      { idx: 24, code: 'lt_LT', name: 'Lithuanian', iconCode: 'lt' },
      { idx: 25, code: 'lv_LV', name: 'Latvian', iconCode: 'lv' },
      { idx: 26, code: 'ru_RU', name: 'Russian', iconCode: 'ru' },
      { idx: 27, code: 'sk_SK', name: 'Slovakian', iconCode: 'it' },
      { idx: 28, code: 'sl_SI', name: 'Slovenian', iconCode: 'sl' },
      { idx: 29, code: 'zh_CN', name: 'Chinese', iconCode: 'cn' }
    ]

    this.state = {
      current: this.locales[0],
      isOpen: false
    }

    this.setLocale = this.setLocale.bind(this)
  }

  setLocale({
    target: {
      dataset: { idx }
    }
  }) {
    const current = this.locales[idx]

    this.setState({ current }, () => this.props.onChange(current.code))
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    return (
      <Dropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={() => this.toggleDropdown()}
        direction="down"
      >
        <DropdownToggle color="outline-dark" caret>
          <FlagIcon code={this.state.current.iconCode} /> {this.state.current.name}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Locale</DropdownItem>
          {this.locales.map((locale, idx) => (
            <DropdownItem
              key={idx}
              data-idx={idx}
              active={this.state.current.idx === idx}
              onClick={this.setLocale}
            >
              <FlagIcon code={locale.iconCode} />
              {locale.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

LocaleSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func
}
