import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

export default class Barcodes extends React.Component {
  renderCodes(barcodes) {
    if (barcodes.length === 0) {
      return null
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {barcodes.map((barcode, idx) => (
            <tr key={idx}>
              <td>{barcode.type}</td>
              <td>{barcode.data}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  hasFront() {
    return this.props.barcodes.some(code => code.side === 'front')
  }

  hasBack() {
    return this.props.barcodes.some(code => code.side === 'back')
  }

  render() {
    return (
      <div style={{ paddingTop: '4px' }}>
        {this.hasFront() && <h5>Front Barcodes:</h5>}
        {this.renderCodes(this.props.barcodes.filter(code => code.side === 'front'))}
        {this.hasBack() && <h5>Back Barcodes:</h5>}
        {this.renderCodes(this.props.barcodes.filter(code => code.side === 'back'))}
      </div>
    )
  }
}

Barcodes.defaultProps = {
  barcodes: []
}

Barcodes.propTypes = {
  barcodes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.string,
      side: PropTypes.oneOf(['front', 'back'])
    })
  )
}
