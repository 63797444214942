import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

export default class VIZ extends React.Component {
  snakeToCapital(str) {
    return str
      .toLowerCase()
      .split('_')
      .map(item => `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
      .join(' ')
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1rem',
            overflowX: 'auto'
          }}
        />
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(this.props.viz).map((key, idx) => {
              if (key === 'mrz_string') {
                return
              }

              const value = this.props.viz[key]

              if (!value) {
                return
              }

              return (
                <tr key={idx}>
                  <th>{this.snakeToCapital(key)}</th>
                  <td>{value}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

VIZ.propTypes = {
  viz: PropTypes.any
}
