import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import PropTypes from 'prop-types'

export default class External extends React.Component {
  constructor(props) {
    super(props)

    const emptyPreset = {
      backendUrl: '',
      middlewareUrl: '',
      tenant: '',
      internalReference: '',
      handoverUrl: ''
    }

    this.state = {
      ...emptyPreset,
      ...(this.props.preset || {})
    }
  }

  componentDidMount() {
    this.props.onChange(this.state)
  }

  setPreset(preset) {
    this.inputChange(preset)
  }

  inputChange(state) {
    this.setState(state, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state)
      }
    })
  }

  render() {
    return (
      <>
        <FormGroup>
          <Label for="backend-url">Backend URL</Label>
          <Input
            type="text"
            name="backendUrl"
            id="backend-url"
            placeholder="Backend Url"
            value={this.state.backendUrl}
            onChange={e => this.inputChange({ backendUrl: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label for="middleware-url">Middleware URL</Label>
          <Input
            type="text"
            name="middlewareUrl"
            id="middleware-url"
            placeholder="Middleware Url"
            value={this.state.middlewareUrl}
            onChange={e => this.inputChange({ middlewareUrl: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label for="tenant">Tenant</Label>
          <Input
            type="text"
            name="tenant"
            id="tenant"
            placeholder="tenant"
            value={this.state.tenant}
            onChange={e => this.inputChange({ tenant: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label for="handover-url">Handover URL</Label>
          <Input
            type="text"
            name="handoverUrl"
            id="handover-url"
            placeholder="Handover Url"
            value={this.state.handoverUrl}
            onChange={e => this.inputChange({ handoverUrl: e.target.value })}
          />
        </FormGroup>
      </>
    )
  }
}

External.propTypes = {
  onChange: PropTypes.func,
  preset: PropTypes.shape({
    backendUrl: PropTypes.string,
    middlewareUrl: PropTypes.string,
    version: PropTypes.number,
    handoverUrl: PropTypes.string
  })
}
