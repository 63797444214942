import React from 'react'
import { ImBin, RiDownloadFill } from 'react-icons/all'
import { BiPlayCircle } from 'react-icons/bi'
import { GoKey, GoLock } from 'react-icons/go'
import { HiOutlineRefresh } from 'react-icons/hi'
import { withRouter } from 'react-router-dom'
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip
} from 'reactstrap'
import PropTypes from 'prop-types'

import { BackendServiceContext } from '../services/BackendService'

import LoadingOverlay from './LoadingOverlay'
import ResultsList from './ResultsList'

class RunVerifai extends React.Component {
  static contextType = BackendServiceContext

  constructor(props) {
    super(props)
    this.state = {
      apiToken: '',
      otp: '',
      startVerifai: false,
      fetchResult: true,
      deleteSession: true,
      errors: [],
      screen: '/',
      results: []
    }
  }

  componentDidUpdate(prevProps) {
    const oldConf = prevProps.config
    const newConf = this.props.config

    if (
      ![
        'documentTypes',
        'countryWhiteList',
        'countryBlackList',
        'handoverUrl',
        'backendUrl',
        'locale',
        'allowEditPrivacyFilters',
        'enableViz',
        'faceMatching',
        'finalImageResult'
      ].every(key => oldConf[key] === newConf[key])
    ) {
      this.setState({ otp: '' })
    }
  }

  getUploadTypes() {
    if (!this.props.config.uploadType) {
      return undefined
    }

    return this.props.config.uploadType.map(item =>
      item === 'phone' ? ['phone', { showSms: this.props.config.showSms }] : item
    )
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ error, errorInfo })
    this.reset().then(() => this.pushError('Frontend Error', error.message, true))
  }

  uuid4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  }

  getOTPData() {
    const { config } = this.props

    return {
      document_type_whitelist: config.documentTypes,
      country_choices_whitelist: config.countryWhiteList,
      country_choices_blacklist: config.countryBlackList,
      handover_base_url: config.handoverUrl,
      allow_edit_privacy_filters: config.allowEditPrivacyFilters,
      locale: config.locale,
      enable_viz: config.enableViz,
      enable_facematch: config.faceMatching,
      internal_reference: this.uuid4()
    }
  }

  toggleInput(key) {
    this.setState(state => ({
      [key]: !state[key]
    }))
  }

  pushError(title, message, error = false) {
    this.setState(({ errors }) => ({
      errors: [{ title, message, error }].concat(errors)
    }))
  }

  reset() {
    return new Promise(accept => {
      this.setState(
        {
          startVerifai: false,
          otp: ''
        },
        accept
      )
    })
  }

  promiseState(state) {
    return new Promise(resolve => this.setState(state, () => resolve(state)))
  }

  addResult(result, sessionId) {
    return new Promise(resolve =>
      this.setState(
        ({ results }) => ({
          results: [
            {
              otp: this.state.otp,
              internalReference: this.state.internalReference,
              sessionId,
              verifaiResult: result,
              dateTime: new Date()
            }
          ].concat(results)
        }),
        () => resolve(result)
      )
    )
  }

  finish(sessionId) {
    this.promiseState({ isLoading: true, startVerifai: false })
      .then(() => this.reset())
      .then(() =>
        this.state.fetchResult
          ? this.context.getResult(
              sessionId,
              this.props.config.finalImageResult,
              this.state.apiToken
            )
          : null
      )
      .catch(e => this.pushError('Result ERROR', e.message, true))
      .then(result =>
        this.state.deleteSession
          ? this.context.deleteSession(sessionId, this.state.apiToken).then(() => result)
          : result
      )
      .then(result => this.addResult(result, sessionId))
      .then(result => this.props.onFinish(result))
      .catch(e => this.pushError('Delete ERROR', e.message, true))
      .finally(() => this.promiseState({ isLoading: false }))
  }

  cancel(sessionId) {
    this.promiseState({ isLoading: true, startVerifai: false, otp: '' })
      .then(() =>
        this.state.deleteSession ? this.context.deleteSession(sessionId, this.state.apiToken) : null
      )
      .catch(e => this.pushError('Delete ERROR', e.message, true))
      .finally(() => this.promiseState({ isLoading: false }))
  }

  render() {
    return (
      <>
        <LoadingOverlay active={this.state.isLoading} />
        <Card style={{ margin: '10px' }}>
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>Run</h3>
            <ButtonGroup>
              <Button
                color="outline-dark"
                onClick={() =>
                  this.setState({ errors: [] }, () => {
                    const data = this.getOTPData()

                    this.context
                      .getOTP(data, this.state.apiToken)
                      .then(response =>
                        this.setState({
                          otp: response.token,
                          internalReference: data.internal_reference
                        })
                      )
                      .catch(e => this.pushError('OTP ERROR', e.message, true))
                  })
                }
              >
                Refresh <HiOutlineRefresh />
              </Button>
              <Button
                disabled={this.state.otp === ''}
                color="outline-dark"
                onClick={() => {
                  this.setState({ startVerifai: true })
                }}
              >
                Start <BiPlayCircle />
              </Button>
            </ButtonGroup>
          </CardHeader>
          <CardBody>
            <InputGroup style={{ marginBottom: '10px' }}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span style={{ paddingRight: '10px' }}>
                    <GoKey />
                  </span>
                  API Token
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="api-token"
                value={this.state.apiToken}
                onChange={e => this.setState({ apiToken: e.target.value })}
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span style={{ paddingRight: '10px' }}>
                    <GoLock />
                  </span>
                  OTP
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={this.state.otp}
                onChange={e => this.setState({ otp: e.target.value })}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText
                  id="fetch-result-group"
                  onClick={() => this.toggleInput('fetchResult')}
                >
                  <span style={{ paddingRight: '10px' }}>
                    <RiDownloadFill />
                  </span>
                  <Input
                    addon
                    type="checkbox"
                    checked={this.state.fetchResult}
                    onChange={() => {}}
                  />
                  <UncontrolledTooltip placement="bottom" target="fetch-result-group">
                    Check to fetch the Verifai result after the flow is done
                  </UncontrolledTooltip>
                </InputGroupText>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <InputGroupText
                  id="delete-session-group"
                  onClick={() => this.toggleInput('deleteSession')}
                >
                  <span style={{ paddingRight: '10px' }}>
                    <ImBin />
                  </span>
                  <Input
                    addon
                    type="checkbox"
                    checked={this.state.deleteSession}
                    onChange={() => {}}
                  />
                  <UncontrolledTooltip placement="bottom" target="delete-session-group">
                    Check to delete the session from the backend after the flow is done
                  </UncontrolledTooltip>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: this.state.startVerifai ? '10px' : 0,
                paddingTop: this.state.startVerifai ? '10px' : 0
              }}
            >
              <div style={this.state.startVerifai ? { border: '1px solid black' } : null}>
                {React.cloneElement(this.props.children, {
                  token: this.state.otp,
                  assetsUrl: this.props.config.assets || undefined,
                  customAssetsUrl: this.props.config.customAssets || undefined,
                  onCanceled: sessionId => this.cancel(sessionId),
                  onSuccess: sessionId => this.finish(sessionId),
                  show: this.state.startVerifai,
                  closable: this.props.config.closable,
                  showGreeting: this.props.config.showGreeting,
                  cautionInfo: this.props.config.cautionInfo,
                  showInstruction: this.props.config.showInstruction,
                  uploadTypes: this.getUploadTypes(),
                  backendUrl: this.props.config.middlewareUrl,
                  theme: this.props.config.theme,
                  faceMatchMobileOnly: this.props.config.faceMatchMobileOnly,
                  nonce: this.props.config.nonce,
                  display: this.props.config.display,
                  countryCodeOption: this.props.config.countryCodeOption,
                  enableAttachments: this.props.config.enableAttachments
                })}
              </div>
            </div>
            <div>
              {this.state.results.length !== 0 && (
                <ResultsList
                  results={this.state.results}
                  show={(result, isIIntel) =>
                    this.props.onFinish(result, isIIntel, this.state.apiToken)
                  }
                />
              )}
            </div>

            {this.state.errors.map(({ title, message, error = false }, idx) => (
              <Alert style={{ marginTop: '10px' }} key={idx} color={error ? 'danger' : 'success'}>
                <h5>{title}</h5>
                {message}
              </Alert>
            ))}
          </CardBody>
        </Card>
      </>
    )
  }
}

RunVerifai.propTypes = {
  config: PropTypes.object,
  onFinish: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }),
  theme: PropTypes.shape({
    color: PropTypes.shape({
      primary: PropTypes.string,
      body: PropTypes.string,
      inverted: PropTypes.string,
      error: PropTypes.string,
      background: PropTypes.string,
      test: PropTypes.string
    })
  }),
  children: PropTypes.node
}

export default withRouter(RunVerifai)
