import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Dot extends Component {
  render() {
    return (
      <div
        style={{
          width: this.props.width,
          height: this.props.height,
          backgroundColor: this.props.fillColor,
          borderColor: this.props.borderColor,
          borderStyle: 'solid',
          borderWidth: this.props.borderWidth,
          borderRadius: '50%',
          display: 'inline-block'
        }}
      />
    )
  }
}

Dot.defaultProps = {
  width: '1rem',
  height: '1rem',
  borderWidth: '0.125rem',
  borderColor: '#212529',
  fillColor: '#fff'
}

Dot.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  fillColor: PropTypes.string
}

export default Dot
