import React from 'react'
import { CgExternal, CgInternal, FaServer } from 'react-icons/all'
import { ButtonGroup } from 'reactstrap'
import PropTypes from 'prop-types'

import { getNested } from '../../Util'
import ButtonDropdown from '../ButtonDropdown'
import DropDownSelect from '../DropdownSelect'

import External from './backend/External'
import Internal from './backend/Internal'

const apiDefault = {
  internal: { default: true, presets: [] },
  external: { presets: [] }
}

export default class Backend extends React.Component {
  constructor(props) {
    super(props)

    this.backends = {
      internal: {
        value: 'internal',
        label: (
          <>
            <CgInternal />
            {' Internal'}
          </>
        )
      },
      external: {
        value: 'external',
        label: (
          <>
            <CgExternal />
            {' External'}
          </>
        )
      }
    }

    this.api =
      Object.keys(this.props.api).filter(key => ['internal', 'external'].includes(key)).length > 0
        ? this.props.api
        : apiDefault

    this.backends = Object.fromEntries(
      Object.keys(this.api).map(key => [
        key,
        {
          ...this.backends[key],
          presets: getNested(this.api, key, 'presets') || [],
          ref: React.createRef(),
          defaultPreset: (getNested(this.api, key, 'presets') || []).find(
            preset => getNested(preset, 'default') || false
          )
        }
      ])
    )

    const state = Object.fromEntries(
      Object.keys(this.api).map(key => [key, getNested(this.backends[key].defaultPreset, 'fields')])
    )

    this.state = {
      backend: this.getDefaultBackend(),
      ...state
    }
  }

  componentDidMount() {
    const config = this.state[this.state.backend]

    this.props.onConfigChange(config)
    this.props.onBackendChange(this.state.backend)
  }

  getDefaultBackend() {
    if (getNested(this.api, 'internal', 'default')) {
      return 'internal'
    }

    if (getNested(this.api, 'external', 'default')) {
      return 'external'
    }

    if (Object.keys(this.api).length === 1) {
      return Object.keys(this.api)[0]
    }

    return 'internal'
  }

  changeBackend(backend) {
    this.setState({ backend }, () => {
      this.props.onBackendChange(backend)
    })
  }

  getCurrentBackend() {
    return this.backends[this.state.backend]
  }

  setBackendPreset(preset) {
    this.getCurrentBackend().ref.current.setPreset(preset)
  }

  onConfigChange(config) {
    this.setState(
      state => ({
        [state.backend]: config
      }),
      () => {
        this.props.onConfigChange(config)
      }
    )
  }

  inputChange(state) {
    this.setState(state, () => this.props.onConfigChange(this.state))
  }

  renderBackend() {
    const current = this.getCurrentBackend()

    if (this.state.backend === 'internal') {
      return (
        <Internal
          onChange={c => this.onConfigChange(c)}
          ref={current.ref}
          preset={this.state.internal}
        />
      )
    }

    return (
      <External
        onChange={c => this.onConfigChange(c)}
        ref={current.ref}
        preset={this.state.external}
      />
    )
  }

  render() {
    return (
      <div className="card" style={{ margin: '10px' }}>
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>
              <FaServer /> Backend Config
            </h3>
            <div style={{ display: 'flex' }}>
              <ButtonGroup>
                {Object.keys(this.backends).length > 1 && (
                  <DropDownSelect
                    default={this.state.backend}
                    onChange={backend => this.changeBackend(backend)}
                    items={Object.keys(this.backends).map(key => ({
                      value: this.backends[key].value,
                      label: this.backends[key].label
                    }))}
                  />
                )}
                {this.getCurrentBackend().presets.length > 0 && (
                  <ButtonDropdown
                    label="Preset"
                    items={this.getCurrentBackend().presets.map(preset => ({
                      label: preset.name,
                      onClick: () => this.setBackendPreset(preset.fields)
                    }))}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="card-body">{this.renderBackend()}</div>
      </div>
    )
  }
}

Backend.defaultProps = apiDefault

Backend.propTypes = {
  onBackendChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func,
  api: PropTypes.shape({
    internal: PropTypes.shape({
      default: PropTypes.bool,
      presets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          default: PropTypes.bool,
          fields: PropTypes.shape({
            backendUrl: PropTypes.string,
            middlewareUrl: PropTypes.string,
            version: PropTypes.number,
            handoverUrl: PropTypes.string
          })
        })
      )
    }),
    external: PropTypes.shape({
      default: PropTypes.bool,
      presets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          default: PropTypes.bool,
          fields: PropTypes.shape({
            backendUrl: PropTypes.string,
            middlewareUrl: PropTypes.string,
            version: PropTypes.number,
            handoverUrl: PropTypes.string
          })
        })
      )
    })
  })
}
