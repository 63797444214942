import React, { Component } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import PropTypes from 'prop-types'

class LoadingOverlay extends Component {
  render() {
    return this.props.active ? (
      <div className="loading-overlay">
        <div className="loading-overlay-inner">
          <PulseLoader size={50} color="#fff" loading={this.props.active} />
        </div>
      </div>
    ) : null
  }
}

LoadingOverlay.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  active: PropTypes.bool
}

export default LoadingOverlay
