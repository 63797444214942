import React, { Component } from 'react'
import {
  AiOutlineFileImage,
  BiWebcam,
  FaUpload,
  IoMdPhonePortrait,
  MdScanner
} from 'react-icons/all'
import PropTypes from 'prop-types'

import CheckBoxList from './CheckBoxList'

class UploadSelect extends Component {
  constructor(props) {
    super(props)

    const uploadList = [
      {
        value: 'file',
        label: 'File',
        default: props.value.includes('file'),
        icon: <AiOutlineFileImage style={{ marginTop: '-2px' }} />,
        id: 'file'
      },
      {
        value: 'phone',
        label: 'Phone',
        default: props.value.includes('phone'),
        icon: <IoMdPhonePortrait style={{ marginTop: '-2px' }} />,
        id: 'phone'
      },
      {
        value: 'webcam',
        label: 'Webcam',
        default: props.value.includes('webcam'),
        icon: <BiWebcam style={{ marginTop: '-2px' }} />,
        id: 'webcam'
      },
      ...(this.isWindowsElectron()
        ? [
            {
              value: 'scanner',
              label: 'Scanner',
              default: props.value.includes('scanner'),
              icon: <MdScanner style={{ marginTop: '-2px' }} />,
              id: 'scanner'
            }
          ]
        : [])
    ]

    this.uploadList = uploadList.map(uploadType => ({
      value: uploadType.value,
      default: uploadType.default,
      ...{
        search: s =>
          uploadType.value.toLowerCase().includes(s) || uploadType.label.toLowerCase().includes(s),
        label: (
          <div>
            {uploadType.icon} {uploadType.label}
          </div>
        ),
        id: `uploadType-${uploadType.id}`
      }
    }))
  }

  onChange(obj) {
    this.props.onChange(Object.keys(obj).filter(key => obj[key]))
  }

  isWindowsElectron() {
    // Check Windows
    if (!window.navigator.userAgent.toLowerCase().includes('windows')) {
      return false
    }

    // Renderer process
    if (
      typeof window !== 'undefined' &&
      typeof window.process === 'object' &&
      window.process.type === 'renderer'
    ) {
      return true
    }

    // Main process
    if (
      typeof process !== 'undefined' &&
      typeof process.versions === 'object' &&
      !!process.versions.electron
    ) {
      return true
    }

    // Detect the user agent when the `nodeIntegration` option is set to false
    if (
      typeof navigator === 'object' &&
      typeof navigator.userAgent === 'string' &&
      navigator.userAgent.indexOf('Electron') >= 0
    ) {
      return true
    }

    return false
  }

  render() {
    return (
      <CheckBoxList
        title={
          <h5>
            <FaUpload /> {this.props.title}
          </h5>
        }
        onChange={items => this.onChange(items)}
        itemList={this.uploadList}
        showSearch={false}
      />
    )
  }
}

UploadSelect.defaultProps = {}

UploadSelect.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array
}

export default UploadSelect
