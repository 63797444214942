import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

import FlagIcon from './FlagIcon'

export default class DropDownSelectExtra extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIdx: props.activeCode ? props.options.find(o => o.code === props.activeCode).idx : 0,
      isOpen: false
    }

    this.set = this.set.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  set({
    target: {
      dataset: { idx }
    }
  }) {
    this.setState({ currentIdx: idx }, this.props.onChange(this.props.options[idx].code))
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    const currentName = this.props.options[this.state.currentIdx].name
    const currentExtra = this.props.options[this.state.currentIdx].iconCode && (
      <FlagIcon code={this.props.options[this.state.currentIdx].iconCode} />
    )

    return (
      <Dropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={this.toggleDropdown}
        direction="down"
      >
        <DropdownToggle color="outline-dark" caret>
          {currentExtra}
          {currentName}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{this.props.title}</DropdownItem>
          {this.props.options.map((option, idx) => (
            <DropdownItem
              key={idx}
              data-idx={idx}
              active={this.state.currentIdx === idx}
              onClick={this.set}
            >
              {option.iconCode && <FlagIcon code={option.iconCode} />}
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

DropDownSelectExtra.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  activeCode: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      idx: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      iconCode: PropTypes.string
    })
  ).isRequired
}
