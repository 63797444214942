import React from 'react'
import PropTypes from 'prop-types'

import CheckBoxList from './CheckBoxList'
import FlagIcon from './FlagIcon'

export default class CountrySelect extends React.Component {
  constructor(props) {
    super(props)

    this.countryList = this.props.countryList.map(c => ({
      value: c,
      label: (
        <div className={`country-div ${c.toLowerCase()}`}>
          <FlagIcon code={c.toLowerCase()} /> <span className="monospace">{c}</span>
        </div>
      ),
      id: `country-${c.toLowerCase()}`,
      search: s => c.toLowerCase().includes(s),
      default: props.default
    }))
  }

  onChange(obj) {
    this.props.onChange(Object.keys(obj).filter(key => obj[key]))
  }

  render() {
    return (
      <CheckBoxList
        title={this.props.title}
        onChange={items => this.onChange(items)}
        itemList={this.countryList}
        showSearch
      />
    )
  }
}

CountrySelect.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  default: PropTypes.bool.isRequired,
  countryList: PropTypes.array
}
