import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactJson from 'react-json-view'

import { BackendServiceContext } from '../services/BackendService'

const INTERVAL_DELAY = 2000

type TimerId = ReturnType<typeof setInterval>

interface IIntelDocumentPage {
  type: string
  image_cropped: {
    url: string
  }
  image_original: {
    url: string
  }
}

interface IIntelImage {
  type: string
  url: string
  isCropped: boolean
}

interface IIntelResultProps {
  internalReference: string
  setImages: (images: Array<IIntelImage>) => void
  apiToken: string
}

function IIntelResult(props: IIntelResultProps) {
  const { internalReference, setImages, apiToken } = props

  const backend = useContext(BackendServiceContext)

  const [result, setResult] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const intervalRef = useRef<TimerId | null>(null)

  const pollResult = useCallback(async () => {
    try {
      const response = await backend.getiIntelResult(internalReference, apiToken)

      setResult(response)

      const newImages = response.identity_document.pages.reduce(
        (acc: Array<Record<string, unknown>>, page: IIntelDocumentPage) => {
          acc.push({ type: page.type, url: page.image_cropped?.url, isCropped: true })
          acc.push({ type: page.type, url: page.image_original?.url, isCropped: false })

          return acc
        },
        []
      )

      newImages.sort((a: IIntelImage, b: IIntelImage) => (b.type as any) - (a.type as any))

      if (response.face_match?.image) {
        newImages.push({
          type: 'face_match',
          url: response.face_match.image.url
        })
      }

      setImages(newImages)
      setIsLoading(false)

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    } catch (error) {
      console.warn(error)
    }
  }, [intervalRef])

  useEffect(() => {
    const timerId = setInterval(pollResult, INTERVAL_DELAY)

    intervalRef.current = timerId

    return function cleanup() {
      clearInterval(timerId)
    }
  }, [])

  if (!result && isLoading) {
    return <div>Loading</div>
  }

  return result ? <ReactJson src={result} /> : null
}

export default IIntelResult
