import React from 'react'
import { FaRegCheckSquare } from 'react-icons/all'
import { FormGroup, Label } from 'reactstrap'
import PropTypes from 'prop-types'

export default class ExtraFeatures extends React.Component {
  constructor(props) {
    super(props)

    this.config = props.settings.fields

    this.state = {
      enableViz: this.config.viz.value,
      faceMatching: this.config.faceMatching.value
    }
  }

  inputChange(state) {
    this.setState(state, () => this.props.onConfigChange(this.state))
  }

  render() {
    return (
      <div className="card" style={{ margin: '10px' }}>
        <div className="card-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>
              <FaRegCheckSquare /> Extra Features Config
            </h3>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-fill justify-content-around flex-wrap">
            {this.config.viz.visible && (
              <div className="flex-fill">
                <FormGroup>
                  <Label for="enable_viz">
                    VIZ
                    <br />
                    <div style={{ position: 'relative', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="enable_viz"
                          value={this.state.enableViz}
                          checked={this.state.enableViz}
                          onChange={e => this.inputChange({ enableViz: e.target.checked })}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </Label>
                </FormGroup>
              </div>
            )}
            {this.config.faceMatching.visible && (
              <div className="flex-fill">
                <FormGroup>
                  <Label for="enable_facematch">
                    Face Matching
                    <br />
                    <div style={{ position: 'relative', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="enable_facematch"
                          value={this.state.faceMatching}
                          checked={this.state.faceMatching}
                          onChange={e => this.inputChange({ faceMatching: e.target.checked })}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </Label>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ExtraFeatures.propTypes = {
  onConfigChange: PropTypes.func,
  settings: PropTypes.any
}
