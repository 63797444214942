import React from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/all'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

export default class MRZ extends React.Component {
  getMRZString() {
    if (this.props.mrz.mrz_string === null) {
      return
    }

    return this.props.mrz.mrz_string.split('\n').map((line, idx) => (
      <div key={idx} className="mrz">
        {line}
      </div>
    ))
  }

  snakeToCapital(str) {
    return str
      .split('_')
      .map(item => `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
      .join(' ')
  }

  getBirthDate(str) {
    if (str.length !== 6 || !/^\d+$/.test(str)) {
      return str
    }

    const now = new Date()
    const year = parseInt(str.substr(0, 2))
    const fullYear = now.getFullYear() < 2000 + year ? 1900 + year : 2000 + year
    const day = parseInt(str.substr(4, 2))
    const month = parseInt(str.substr(2, 2))
    const date = new Date(fullYear, month, day)

    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  }

  getExpiryDate(str) {
    if (str.length !== 6 || !/^\d+$/.test(str)) {
      return str
    }

    const year = parseInt(str.substr(0, 2))
    const fullYear = 2000 + year
    const day = parseInt(str.substr(4, 2))
    const month = parseInt(str.substr(2, 2))
    const date = new Date(fullYear, month, day)

    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  }

  renderValue(key, value) {
    if (key === 'date_of_birth') {
      return this.getBirthDate(value)
    }

    if (key === 'date_of_expiry') {
      return this.getExpiryDate(value)
    }

    if (typeof value === 'boolean') {
      return value ? (
        <AiOutlineCheckCircle style={{ color: 'green' }} />
      ) : (
        <AiOutlineCloseCircle style={{ color: 'red' }} />
      )
    }

    return value
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1rem',
            overflowX: 'auto'
          }}
        >
          <div>{this.getMRZString()}</div>
        </div>
        <hr />
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(this.props.mrz).map((key, idx) => {
              if (key === 'mrz_string') {
                return
              }

              const value = this.props.mrz[key]

              if (value === null) {
                return
              }

              return (
                <tr key={idx}>
                  <th>{this.snakeToCapital(key)}</th>
                  <td>{this.renderValue(key, value)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

MRZ.propTypes = {
  mrz: PropTypes.object
}
