import React from 'react'
import { FaFlag, FaPassport } from 'react-icons/fa'
import { GiResize } from 'react-icons/gi'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { ImPriceTag } from 'react-icons/im'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

export default class IdModel extends React.Component {
  render() {
    return (
      <div style={{ paddingTop: '4px' }}>
        <Table>
          <tbody>
            <tr>
              <th>
                <HiOutlineDocumentDuplicate /> Model
              </th>
              <td>{this.props.model}</td>
            </tr>
            <tr>
              <th>
                <FaFlag /> Country
              </th>
              <td>{this.props.country}</td>
            </tr>
            <tr>
              <th>
                <FaPassport /> Document Type
              </th>
              <td>{this.props.type}</td>
            </tr>
            <tr>
              <th>
                <ImPriceTag /> Uuid
              </th>
              <td>{this.props.uuid}</td>
            </tr>
            <tr>
              <th>
                <GiResize /> Dimensions
              </th>
              <td>
                {this.props.width_mm}mm x {this.props.height_mm}mm
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

IdModel.propTypes = {
  model: PropTypes.string,
  country: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string,
  width_mm: PropTypes.number,
  height_mm: PropTypes.number
}
