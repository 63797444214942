import React from 'react'
import { AiOutlineIdcard, FaCarSide, FaPassport, FaSuitcase, FiGlobe } from 'react-icons/all'
import PropTypes from 'prop-types'

import CheckBoxList from './CheckBoxList'

export default class DocumentSelect extends React.Component {
  constructor(props) {
    super(props)

    const documentList = [
      {
        value: 'P',
        label: 'Passport',
        default: props.value.includes('P'),
        icon: <FaPassport style={{ marginTop: '-2px' }} />,
        id: 'passport'
      },
      {
        value: 'I',
        label: 'Id Card',
        default: props.value.includes('I'),
        icon: <AiOutlineIdcard style={{ marginTop: '-2px' }} />,
        id: 'id-card'
      },
      {
        value: 'D',
        label: 'Drivers licence',
        default: props.value.includes('D'),
        icon: <FaCarSide style={{ marginTop: '-2px' }} />,
        id: 'drivers-licence'
      },
      {
        value: 'RP',
        label: 'Residence Permit',
        default: props.value.includes('RP'),
        icon: <FaSuitcase style={{ marginTop: '-4px' }} />,
        id: 'residence-permit'
      },
      {
        value: 'R',
        label: 'Refugee',
        default: props.value.includes('R'),
        icon: <FiGlobe style={{ marginTop: '-4px' }} />,
        id: 'refugee'
      }
    ]

    this.documentList = documentList.map(doc => ({
      value: doc.value,
      default: doc.default,
      ...{
        search: s => doc.value.toLowerCase().includes(s) || doc.label.toLowerCase().includes(s),
        label: (
          <div>
            {doc.icon} {doc.label}
          </div>
        ), // <Button className="btn btn-outline-dark">{doc.icon} {doc.label}</Button>,
        id: `document-${doc.id}`
      }
    }))
  }

  onChange(obj) {
    this.props.onChange(Object.keys(obj).filter(key => obj[key]))
  }

  render() {
    return (
      <CheckBoxList
        title={
          <h5>
            <FaPassport /> {this.props.title}
          </h5>
        }
        onChange={items => this.onChange(items)}
        itemList={this.documentList}
        showSearch={false}
      />
    )
  }
}

DocumentSelect.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array
}
