import React from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'

export default class DropDownSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: this.findCurrent(),
      isOpen: false
    }
  }

  findCurrent() {
    const current = this.props.items.findIndex(item => item.value === this.props.default)

    return current >= 0 ? current : 0
  }

  renderItems() {
    return (
      <>
        {this.props.items.map((item, idx) => (
          <DropdownItem
            key={idx}
            active={this.state.current === idx}
            onClick={() => {
              this.setState({ current: idx })
              this.props.onChange(item.value)
            }}
          >
            {item.label}
          </DropdownItem>
        ))}
      </>
    )
  }

  toggleDropdown() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    return (
      <ButtonDropdown
        id={this.props.id}
        isOpen={this.state.isOpen}
        toggle={() => this.toggleDropdown()}
      >
        <DropdownToggle color="outline-dark" caret>
          {this.props.items[this.state.current].label}
        </DropdownToggle>
        <DropdownMenu>{this.renderItems()}</DropdownMenu>
      </ButtonDropdown>
    )
  }
}

DropDownSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ),
  default: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string
}
